<template>
  <div>

    <div
      class=" conOfCampaignHead d-flex align-items-center "
    >
      <b-col md="4">
        File
      </b-col>
      <b-col md="4">
        Slider
      </b-col>

      <b-col md="4">
        Status
      </b-col>

    </div>
    <draggable
      v-model="sliders"

      group="people"
      @start="drag=true"
      @change="moved"
      @end="drag=false"
    >
      <div
        v-for="element in sliders"
        :key="element.id"
        class=" conOfCampaign d-flex align-items-center "
      >

        <b-col md="4">
          <video
            v-if="getFileExtension(element.video)=='mp4'"
            id="video-preview"
            controls
            :src="element.video"
            width="200"
          />
          <b-img
            v-else
            v-img
            width="200"
            :src="element.video"
          /></b-col>
        <b-col md="4">
          {{ element.name }}
        </b-col>

        <b-col md="4">
          {{ getTypes(element.is_active) }}
        </b-col>

      </div>
    </draggable>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  components: { draggable },
  data() {
    return {
      sliders: [],
      types: [
        {
          name: 'Active Campaigns',
          id: 1,
        }],
      filter: {
        type: 'active',
      },
      allTypes: '',
      APIURL: 'admin/product_gifts',
      addType: 'Add New Campaign',
      type: 'page',
      urlType: 'sliders',
      addComponentName: 'add-campaign',
      editComponent: 'edit-campaign',
      viwComponent: 'show-campaign',
      columns: [
        { key: 'id', sortable: true },
        { key: 'video', label: 'File' },
        { key: 'name', label: 'Name' },
        { key: 'is_active', label: 'status' },
        { key: 'actions' },
      ],
    }
  },
  created() {
    this.getSlider()
  },
  methods: {
    filterTypes(type) {
      const filteredType = this.types.filter(item => item.text !== type)
      this.allTypes = filteredType
    },
    getTypes(type) {
      if (type === 1) {
        return 'Active'
      } if (type === 0) {
        return 'Not Active'
      }
      return 'Active'
    },
    getFileExtension(fileName) {
      return fileName.split('.').pop().toLowerCase()
    },
    moved(item) {
      const index = item.moved.newIndex + 1
      axios.post(`admin/arrange_sliders/${item.moved.element.id}?arrange=${index}`).then(res => {
        if (res.status === 200) {
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            type: 'sucess',
            duration: 3000,
          })
        } else {
          this.$toasted.show('Something went wrong', {
            position: 'top-center',
            type: 'error',
            duration: 3000,
          })
        }
      })
    },
    async getSlider() {
      await axios.get('admin/sliders?is_active=1').then(res => {
        // await axios.get('admin/product_gifts').then(res => {
        if (res.status === 200) {
          this.sliders = res.data.data.sliders
        }
      })
    },
  },
}
</script>

<style scoped>
.conOfCampaign{
  cursor:all-scroll;
  background: #fff;
    padding: 8px;
    border-bottom: 1px solid #e4dede;}
    .conOfCampaign:hover{
      background: #e8e8e8;

    }
    .conOfCampaignHead{
  background: #fff;
    padding: 8px;
    border-bottom: 1px solid #e4dede;
  }
  .giftName{
    padding-left: 1rem;
  }

</style>
